import Web3 from "web3";
import axios from "axios";

/**
 * Load Web3.js
 */
export const getWeb3 = () =>
  new Promise(async (resolve, reject) => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        resolve(web3);
      } catch (error) {
        reject(error);
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3;
      // console.log("Injected web3 detected.");
      resolve(web3);
    }
    // Fallback to localhost; use dev console port by default...
    else {
      const provider = new Web3.providers.HttpProvider("http://127.0.0.1:8545");
      const web3 = new Web3(provider);
      // console.log("No web3 instance injected, using Local web3.");
      resolve(web3);
    }
  });

export const getGasPrice = async () => {
  const response = await axios.get(
    "https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=N8JFEVIE2EB53FQKZBY7H5ZDH2TUP22Z7Y"
  );

  return {
    low: response.data.result.SafeGasPrice ,
    medium: response.data.result.ProposeGasPrice ,
    high: response.data.result.FastGasPrice,
    fastest: Math.round(response.data.result.FastGasPrice),
  };
};

export const lookUpPrices = async function (id_array) {
  let ids = id_array.join("%2C");
  let res = await fetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=" +
      ids +
      "&vs_currencies=usd"
  );
  return res.json();
};
