import React from "react";
import styled from "styled-components";

import cn from "classnames";

// lg, md
const WParagraph = ({ children, bold = false, alert = false, heading = false, ...rest }) => {
  return (
    <WParagraphWrapper className={cn({ bold, alert, heading })} {...rest}>
      {children}
    </WParagraphWrapper>
  );
};

const WParagraphWrapper = styled.p`
  color: #ddd;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 20px;

  &.heading {
    font-weight: 700;
    color: white;
    font-size: 1.5rem;
  }

  &.bold {
    font-weight: 700;
    color: white;
  }

  &.alert {
    color: #ff3232;
    font-size: 0.8875rem;
    font-style: italic;
  }

  .mark {
    color: #ee33b1;
  }

  .bold {
    font-weight: 600;
  }

  .italic {
    font-style: italic;
  }
`;

export default WParagraph;
