import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { TOKEN_SYMBOL } from "../../helpers/constant";
import { PROD_WHL_TOKEN_ADDRESS } from "../../helpers/contract";

import stakingActions from "../../redux/staking/actions";

const Announcement = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(stakingActions.getWHLPrice());
  }, [dispatch]);

  const whlPrice = useSelector((state) => state.Staking.whlPrice);

  return (
    <AnnouncementWrapper>
      {TOKEN_SYMBOL}:{" "}
      <span className="token-price">{`$${whlPrice}`}</span>{" "}
      <a
        target="_blank"
        href={`https://whl.gitbook.io/community-guide/faq/uniswap`}
        className="more-info"
      >
        Uniswap
      </a>
      <span className="details">|</span>
      <a target="_blank" href="https://whaleroom.org" className="whale-room-info">
        WhaleRoom.org
      </a>
      {` `}
      <span className="details">Ecosystem</span>
    </AnnouncementWrapper>
  );
};

export default Announcement;

const AnnouncementWrapper = styled.div`
  width: 100%;
  height: 49px;
  background: #36404a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .token-price {
    display: inline-block;
    background: white;
    color: #36404a;
    padding: 2px 5px;
    border-radius: 28px;
    margin-left: 10px;
    font-weight: 700;
  }

  .more-info {
    color: white;
    font-weight: 700;
    padding-left: 20px;
    text-decoration: underline;
  }

  .whale-room-info {
    color: white;
    text-decoration: none;
  }

  .details {
    padding-left: 5px;
    padding-right: 5px;
  }
`;
