import { getGasPrice } from "./index";
import { getGasFee } from "../../helpers/contract";

export const getBalanceAsync = async (instance, address) => {
  return await instance.methods
    .balanceOf(address)
    .call()
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getAllowanceAsync = async (instance, owner, sender) => {
  return await instance.methods
    .allowance(owner, sender)
    .call()
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDividendsAsync = async (instance, address) => {
  return await instance.methods
    .dividendsOf(address)
    .call()
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const getContractMultiDataAsync = async (instance) => {
  return await instance.methods
    .multiData()
    .call()
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const approveAsync = async (
  instance,
  web3,
  amount,
  address,
  spender
) => {
  const prices = await getGasPrice();

  // Get gas limit
  const gasLimit = await instance.methods
    .approve(
      spender,
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    )
    .estimateGas({ from: address });

  return await instance.methods
    .approve(
      spender,
      "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
    )
    .send({
      from: address,
      gasPrice: web3.utils.toWei(prices.medium, "gwei"),
      gas: getGasFee(gasLimit),
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// Stake
export const stakeAsync = async (
  instance,
  web3,
  amount,
  referredBy,
  address
) => {
  const prices = await getGasPrice();

  // Get gas limit
  const gasLimit = await instance.methods
    .buy(amount.toFixed().toString(), referredBy)
    .estimateGas({ from: address });

  return await instance.methods
    .buy(amount.toFixed().toString(), referredBy)
    .send({
      from: address,
      gasPrice: web3.utils.toWei(prices.medium, "gwei"),
      gas: getGasFee(gasLimit),
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// Sell
export const sellAsync = async (instance, web3, amount, address) => {
  const prices = await getGasPrice();

  // Get gas limit
  const gasLimit = await instance.methods
    .sell(amount.toFixed().toString())
    .estimateGas({ from: address });

  return await instance.methods
    .sell(amount.toFixed().toString())
    .send({
      from: address,
      gasPrice: web3.utils.toWei(prices.medium, "gwei"),
      gas: getGasFee(gasLimit),
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// Reinvest
export const reinvestAsync = async (instance, web3, address) => {
  const prices = await getGasPrice();

  // Get gas limit
  const gasLimit = await instance.methods
    .reinvest()
    .estimateGas({ from: address });

  return await instance.methods
    .reinvest()
    .send({
      from: address,
      gasPrice: web3.utils.toWei(prices.medium, "gwei"),
      gas: getGasFee(gasLimit),
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// Withdraw
export const withdrawAsync = async (instance, web3, address) => {
  const prices = await getGasPrice();

  // Get gas limit
  const gasLimit = await instance.methods
    .withdraw()
    .estimateGas({ from: address });

  return await instance.methods
    .withdraw()
    .send({
      from: address,
      gasPrice: web3.utils.toWei(prices.medium, "gwei"),
      gas: getGasFee(gasLimit),
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
