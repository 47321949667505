import async from "../components/common/Async";

const Stake = async(() => import("../pages/Stake"));
const Sell = async(() => import("../pages/Sell"));
const Reinvest = async(() => import("../pages/Reinvest"));
const Profile = async(() => import("../pages/Profile"));

export default [
  {
    path: '/add',
    component: Stake,
  },
  {
    path: '/remove',
    component: Sell,
  },
  {
    path: '/restake',
    component: Reinvest,
  },
  {
    path: '/profile',
    component: Profile,
  }
];
