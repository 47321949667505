export const getValueFromObject = (obj, key, def = null) => {
  if (obj === null) {
    return def;
  }

  if (key in obj) {
    return obj[key];
  }

  return def;
};

export const convertFromWei = (val, fixed = 4) =>
  (Number(val !== null ? val : 0) / Math.pow(10, 18)).toFixed(fixed);
