import actions from "./actions";

const initState = {
  whlPrice: 0,
  whlAllowance: 0,
  whlBalance: 0,
  stakedAmount: 0, // staked amount
  dividendsAmount: 0,
  contract: []
};

export default function stakingReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_WHL_PRICE_SUCCESS:
      return {
        ...state,
        whlPrice: action.whlPrice,
      };
    case actions.GET_WHL_BALANCE_SUCCESS:
      return {
        ...state,
        whlBalance: action.whlBalance,
      };
    case actions.GET_STAKED_AMOUNT_SUCCESS:
      return {
        ...state,
        stakedAmount: action.stakedAmount,
      };
    case actions.GET_WHL_ALLOWANCE_SUCCESS:
      return {
        ...state,
        whlAllowance: action.whlAllowance,
      };
    case actions.GET_DIVIDENDS_AMOUNT_SUCCESS:
      return {
        ...state,
        dividendsAmount: action.dividendsAmount,
      };
    case actions.GET_CONTRACT_MULTI_DATA_SUCCESS:
      return {
        ...state,
        contract: [...action.data],
      };
    default:
      return state;
  }
}
