import React, { useEffect } from "react";
import { useWallet } from "use-wallet";
import { useDispatch, useSelector } from "react-redux";

import { Header, Menu, OtherInfo } from "../../components/layout";
import { MainWrapper } from "../../components/Wrappers";
import {
  PageTitle,
  Separator,
  WButton,
  WParagraph,
  WLink,
} from "../../components/common";
import { TOKEN_SYMBOL, REWARD_TOKEN_SYMBOL } from "../../helpers/constant";
import { PROD_WHL_STAKING_ADDRESS } from "../../helpers/contract";

import stakingActions from "../../redux/staking/actions";

const LandingPage = () => {
  const dispatch = useDispatch();
  const { connect, status } = useWallet();

  useEffect(() => {
    if (status === "connected") {
      dispatch(stakingActions.getContractMultiData());
    }
  }, [dispatch, status]);

  const contract = useSelector((state) => state.Staking.contract);

  return (
    <>
      <Header />
      <Menu />
      <OtherInfo data={contract} />
      <MainWrapper>
        <PageTitle title="Welcome" />
        <Separator />
        {status !== "connected" && (
          <>
            <WParagraph>Connect to your web3 browser</WParagraph>
            <WButton size="lg" onClick={() => connect()}>
              CONNECT
            </WButton>
          </>
        )}

        <p>
          <span style={{ color: 'rgb(255, 255, 255)', lineHeight: 1.5 }}>
            <strong>New to the loyalty staking game?</strong>{' '}
            <a style={{ color:'white'}} href="https://whaleroom.org/loyalty-game/">
              Click here to learn more before staking!
            </a>
          </span>
        </p>
        {/* <p>
          <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
            The WhaleRoom social platform has been coded to interface with any
            ERC20 token but it makes use of a two token system for users to
            participate directly with one another. Below we explain how room
            tokens (any ERC-20) differ from the platform tokens (WHL) and the
            pool tokens (WHLC). Although the explanation is somewhat technical,
            the result of this approach is to create a means of incentivizing
            people to build communities by flipping the usual model of tech
            companies. We provide the platform to most users for free, users pay
            one another for building communities, and a decentralized system
            with public code controls the system of P2P payments and staking.
            For true whales, we sell paid memberships that offer a number of
            benefits. For everyone else, we offer a P2P system of building
            communities in which users effectively pay one another for their
            participation.
          </span>
        </p>
        <p></p> */}
        {/* <p>
          <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
            To drive an amazing experience and awesome benefits for WhaleRoom
            members, we have set up a system in which users interact directly
            with each other, rather than paying or interfacing with a
            centralized company. WHL token holders can stake their tokens with
            one another via our P2P &ldquo;staking&rdquo; system. This P2P
            staking system is fully automated and operates autonomously via a
            smart contract (hosted on the Ethereum network). After the launch of
            the staking program, it is impossible to change or alter the staking
            system in any way, and we have set this up so that users can be
            assured that they&rsquo;re interacting with other users We are
            pioneering a new model in which community members can profit from
            their own participation and the participation of others, in a direct
            way.&nbsp;
          </span>
        </p>
        <p></p>
        <p>
          <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
            With our unique model, there is no signup or room entry fees that
            are collected by WhaleRoom Labs Inc. We believe that this is the
            future of chat rooms and that users should benefit from the
            popularity of the communities that they create.
          </span>
        </p>
        <p></p>
        <p>
          <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
            WhaleRoom Labs Inc. does not own or receive any of the WHL tokens
            (which are ERC-20 tokens). WHL tokens are used between members to
            unlock member benefits on our platform, and they are interoperable
            with any other chat platform that makes use of WHL, which anyone can
            do thanks to the permissionless nature of Ethereum tokens.
          </span>
        </p>
        <p></p> */}
        {/* <p>
          <span style={{ color: "#fff", lineHeight: 1.5 }}>
            <strong>
              The WHL Token P2P Staking Program is a Smart Contract-based System
            </strong>
          </span>
        </p>
        <p></p>
        <p>
          <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
            In the spirit of decentralization, WhaleRoom Labs Inc. can&rsquo;t
            control the staking system smart contract or edit or even upgrade it
            once it is deployed. That said, each user that stakes WHL is
            participating in a peer-to-peer loyalty program wherein each user
            contributes to the program according to the exact same set of rules.
            We have tried here to describe the smart contract rules, but there
            is no substitute for reading the code and carefully understanding
            this system because it is a novel approach to the problem of how
            social communities can build value for themselves, rather than for
            the benefit of centralized companies like Facebook, Discord, etc.
          </span>
        </p>
        <p></p> */}

        {/*<p>*/}
        {/*  <span style={{ color: "#fff", lineHeight: 1.5 }}>*/}
        {/*    <strong>*/}
        {/*      The P2P WHL social token staking program is a smart contract-based system*/}
        {/*    </strong>*/}
        {/*  </span>*/}
        {/*</p>*/}
        {/*<p></p>*/}
        {/*<p>*/}
        {/*  <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>*/}
        {/*    In the spirit of decentralization, WhaleRoom can&rsquo;t*/}
        {/*    control the staking system smart contract or edit or even upgrade it*/}
        {/*    once it is deployed. That said, each user that stakes WHL social tokens is*/}
        {/*    participating in a peer-to-peer loyalty program wherein each user*/}
        {/*    contributes to the program according to the exact same set of rules.*/}
        {/*    We have tried here to describe the smart contract rules, but there*/}
        {/*    is no substitute for reading the code and carefully understanding*/}
        {/*    this system because it is a novel approach to the problem of how*/}
        {/*    social communities can build value for themselves, rather than for*/}
        {/*    the benefit of centralized companies like Facebook, Discord, etc.*/}
        {/*  </span>*/}
        {/*</p>*/}
        {/*<p></p>*/}

        {/*<p>*/}
        {/*  <span style={{ color: "#fff", lineHeight: 1.5 }}>*/}
        {/*    <strong>*/}
        {/*      How does the gamified loyalty staking system work?*/}
        {/*    </strong>*/}
        {/*  </span>*/}
        {/*</p>*/}
        {/*<p></p>*/}
        {/*<p>*/}
        {/*  <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>*/}
        {/*    Upon entry or exit, wallets incur a 10% smart contract penalty to their WHL */}
        {/*    social token wallet address balance. All already-staked wallet addresses*/}
        {/*    each receive these entry/exit penalties in the form of WHL social tokens*/}
        {/*    according to their share of the smart contract pool (WHL:WHLC). Do not participate*/}
        {/*    in this loyalty staking game if you are not committed to the loyalty game*/}
        {/*    in the long-term.*/}
        {/*  </span>*/}
        {/*</p>*/}

        {/* <p>
          <span style={{ color: "#fff", lineHeight: 1.5 }}>
            <b>The Benefits of the WHL P2P Community Staking Program</b>
          </span>
        </p>
        <p></p>
        <ul style={{ listStyle: "none" }}>
          <li style={{ fontWeight: 400, ariaLevel: 1 }}>
            <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
              1) Earn WHL tokens by staking to receive a pro-rata portion of the
              10% P2P entry and exit fees from users entering and leaving the
              staking system smart contract (i.e. growth or contraction of the
              members).&nbsp;
            </span>
          </li>
          <li style={{ fontWeight: 400, ariaLevel: 1 }}>
            <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
              2) Earn WHL tokens by staking to receive a pro-rata portion of
              100% of the community P2P signup and room entry fees charged by
              the smart contract staking system (i.e. direct participation in
              the success of the platform, without a middleman).&nbsp;
            </span>
          </li>
          <li style={{ fontWeight: 400, ariaLevel: 1 }}>
            <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>
              3) All WhaleRoom platform feature upgrades that would normally be
              paid are yours forever. We are big proponents of the value of
              decentralization.
            </span>
          </li>
        </ul>
        <p></p> */}

        {/*<p>*/}
        {/*  <span style={{ color: "#fff", lineHeight: 1.5 }}>*/}
        {/*    <b>The benefits of the WHL social token staking system</b>*/}
        {/*  </span>*/}
        {/*</p>*/}
        {/*<p></p>*/}
        {/*<ul style={{ listStyle: "none" }}>*/}
        {/*  <li style={{ fontWeight: 400, ariaLevel: 1 }}>*/}
        {/*    <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>*/}
        {/*      1) Earn WHL social tokens by staking to receive a pro-rata portion*/}
        {/*      of the 10% P2P entry and exit fees from users entering and leaving*/}
        {/*      the staking system smart contract (i.e. growth or contraction of*/}
        {/*      the staked wallet addresses).*/}
        {/*    </span>*/}
        {/*  </li>*/}
        {/*  <p></p>*/}
        {/*  <li style={{ fontWeight: 400, ariaLevel: 1 }}>*/}
        {/*    <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>*/}
        {/*      2) All WhaleRoom platform feature upgrades that would normally*/}
        {/*      be paid are yours forever. We are big proponents of the value*/}
        {/*      of decentralization.*/}
        {/*    </span>*/}
        {/*  </li>*/}
        {/*</ul>*/}

        {/*<p>*/}
        {/*  <span style={{ color: "#fff", lineHeight: 1.5 }}>*/}
        {/*    <b>Technical Warning for New Stakers (avoid this mistake!)</b>*/}
        {/*  </span>*/}
        {/*</p>*/}
        {/*<p></p>*/}
        {/*<p>*/}
        {/*  <span style={{ fontWeight: 400, color: "#fff", lineHeight: 1.5 }}>*/}
        {/*    When you stake your WHL social tokens, you are given a quantity of WHLC*/}
        {/*    social tokens to account for your portion of the P2P loyalty program pool*/}
        {/*    that you have entered. Your staked WHL social tokens are converted by the*/}
        {/*    smart contract to WHLC social tokens which are then exchanged 1:1 for WHL*/}
        {/*    social tokens upon entering the staking program, vise-versa (minus*/}
        {/*    entry/exit P2P fees). This is how the P2P smart contract knows how*/}
        {/*    many rewards to redistribute to each staked wallet address.*/}
        {/*  </span>*/}
        {/*</p>*/}
        <p></p>
        <hr/>
        <p>
          <span style={{ color: "red", lineHeight: 1.5, fontStyle: "italic" }}>
            Never transfer WHLC to another wallet than the wallet they were received in
            (doing so will result in the loss of all your WHL social tokens, rewards, and
            position inside the pool)
          </span>
        </p>
      </MainWrapper>
    </>
  );
};

export default LandingPage;
