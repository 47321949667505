import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Header, Menu, OtherInfo } from "../../components/layout";
import { MainWrapper } from "../../components/Wrappers";

const Layout = ({ children }) => {
  const contract = useSelector((state) => state.Staking.contract);

  return (
    <LayoutWrapper>
      <Header />
      <Menu />
      <OtherInfo data={contract} />
      <MainWrapper>{children}</MainWrapper>
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  width: 100%;
`;

export default Layout;
