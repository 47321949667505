import React from "react";
import styled from "styled-components";

import RowValue from "../RowValue";
import {
  TOKEN_SYMBOL,
  REWARD_TOKEN_SYMBOL,
  CLUB_RECEIVE_AMOUNT,
} from "../../helpers/constant";

const InputAmount = ({ balance, amount, isStake = true, onSetAmount }) => {
  return (
    <InputAmountWrapper>
      <div className="input-section">
        <span>Send</span>
        <input
          type="text"
          className="input-section-input"
          value={amount}
          onChange={(e) => onSetAmount(e.target.value, false)}
        />
        {isStake ? TOKEN_SYMBOL : REWARD_TOKEN_SYMBOL}
      </div>
      <div className="percent-section">
        <button
          className="percent-section-btn"
          onClick={(e) => onSetAmount(balance * 0.25, false)}
        >
          25%
        </button>
        <button
          className="percent-section-btn"
          onClick={(e) => onSetAmount(balance * 0.5, false)}
        >
          50%
        </button>
        <button
          className="percent-section-btn"
          onClick={(e) => onSetAmount(balance * 0.75, false)}
        >
          75%
        </button>
        <button
          className="percent-section-btn"
          onClick={(e) => onSetAmount(balance, true)}
        >
          100%
        </button>
      </div>
      <RowValue
        className="receive-section"
        className="receive-section"
        title="Receive"
        value={`${(amount * CLUB_RECEIVE_AMOUNT).toFixed(4)} ${
          isStake ? REWARD_TOKEN_SYMBOL : TOKEN_SYMBOL
        }`}
      />
    </InputAmountWrapper>
  );
};

const InputAmountWrapper = styled.div`
  .input-section {
    color: #ddd;

    &-input {
      margin-left: 10px;
      margin-right: 10px;
      background: #262e35;
      outline: none;
      border: none;
      color: white;
      text-align: center;
      width: 230px;
      height: 30px;
    }
  }

  .percent-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &-btn {
      width: 24%;
      height: 32px;
      border: none;
      outline: none;
      background: #262e35;
      color: #fff;
      cursor: pointer;
    }
  }

  .receive-section {
    margin-top: 20px;
  }
`;
export default InputAmount;
