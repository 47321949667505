import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useWallet } from "use-wallet";

import { Loader } from "../components/common";
import Layout from "../containers/Layout";
import { LandingPage } from "../pages";

import menuRoutes from "./menuRoutes";

const Routes = () => {
  const { status } = useWallet();

  return (
    <Suspense fallback={Loader}>
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/home" exact component={LandingPage} />
        {status === "connected" &&
          menuRoutes.map(({ path, component: Component }, index) => (
            <Route
              key={index}
              path={path}
              exact
              render={(props) => (
                <Layout>
                  <Component {...props} />
                </Layout>
              )}
            />
          ))}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
