export const NETWORK = {
  MAIN: 1,
  ROPSTEIN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  KOVAN: 42,
};

export const getNetworkChainId = () => {
  const { REACT_APP_BUILD_MODE } = process.env;

  if (REACT_APP_BUILD_MODE === "production") {
    return NETWORK.MAIN;
  } else {
    return NETWORK.RINKEBY;
    // return NETWORK.ROPSTEIN;
  }
};

export const RESPONSE = {
  SUCCESS: 100,
  INSUFFICIENT: 200,
  ERROR: 300,
  SHOULD_APPROVE: 400,
  SHOULD_STAKE: 500,
};

export const TOKEN_SYMBOL = "WHL";
export const REWARD_TOKEN_SYMBOL = "WHLC";

// Club Fee (Fee is 10%)
export const CLUB_RECEIVE_AMOUNT = 0.9;

// Stake ReferredBy Address
export const REFERRED_BY = process.env.REACT_APP_REFERRED_BY_ADDRESS;
