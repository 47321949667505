import React from "react";
import styled from "styled-components";

const Separator = () => {
  return <SeparatorWrapper />;
};

const SeparatorWrapper = styled.div`
  background: white;
  width: 70px;
  height: 8px;
`;

export default Separator;
