import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useWallet } from "use-wallet";

import cn from "classnames";

const Menu = () => {
  const { status } = useWallet();

  return (
    <MenuWrapper>
      <ul>
        <li>
          <Link
            to="/add"
            className={cn("menu-link", { isDisabled: status !== "connected" })}
          >
            Add
          </Link>
        </li>
        <li>
          <Link
            to="/remove"
            className={cn("menu-link", { isDisabled: status !== "connected" })}
          >
            Remove
          </Link>
        </li>
        <li>
          <Link
            to="restake"
            className={cn("menu-link", { isDisabled: status !== "connected" })}
          >
            Add Again or Remove
          </Link>
        </li>
        <li>
          <Link
            to="profile"
            className={cn("menu-link", { isDisabled: status !== "connected" })}
          >
            Profile
          </Link>
        </li>
      </ul>
    </MenuWrapper>
  );
};

export default Menu;

const MenuWrapper = styled.div`
  width: 100%;
  background: #36404a;
  height: 60px;

  ul {
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 0px 16px;

    .menu-link {
      color: white;
      font-weight: 600;
      text-decoration: none;
      font-size: 1.2rem;
      cursor: pointer;

      &.isDisabled {
        cursor: not-allowed;
        color: #aba3a3;
        pointer-events: none;
      }
    }
  }
`;
