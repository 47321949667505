import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import SellBoard from "../../containers/SellBoard";
import { PageTitle, Separator, WParagraph } from "../../components/common";
import RowValue from "../../components/RowValue";

import { REWARD_TOKEN_SYMBOL } from "../../helpers/constant";
import { convertFromWei } from "../../helpers/utils";

import stakingActions from "../../redux/staking/actions";

const SellPage = () => {
  const dispatch = useDispatch();

  const stakedAmount = useSelector((state) => state.Staking.stakedAmount);

  useEffect(() => {
    dispatch(stakingActions.getStakedAmount());
    dispatch(stakingActions.getContractMultiData());
  }, [dispatch]);

  return (
    <SellWrapper>
      <PageTitle title="Remove" />
      <Separator />
      {stakedAmount <= 0 && (
        <WParagraph>
          Nothing to remove :( <br />
          Check out the add section to get some!
        </WParagraph>
      )}
      {stakedAmount > 0 && (
        <>
          <RowValue
            className="balance-section"
            title="My WHLC Points"
            value={`${convertFromWei(stakedAmount, 2)} ${REWARD_TOKEN_SYMBOL}`}
          />
          <SellBoard />
        </>
      )}
    </SellWrapper>
  );
};

const SellWrapper = styled.div`
  .balance-section {
    margin-top: 30px;
    font-size: 1.3rem;
  }
`;

export default SellPage;
