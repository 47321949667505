import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";

import { WButton } from "../../components/common";
import InputAmount from "../../components/InputAmount";
import { FlexColumnHorizontalCenterWrapper } from "../../components/Wrappers";

import { RESPONSE } from "../../helpers/constant";
import { convertFromWei } from "../../helpers/utils";

import stakingActions from "../../redux/staking/actions";

const SellBoard = () => {
  const dispatch = useDispatch();

  const stakedAmount = useSelector((state) =>
    convertFromWei(state.Staking.stakedAmount, 2)
  );

  const [amount, setAmount] = useState("0");
  const [isMax, setIsMax] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkAmount = (amount) => {
    if (isNaN(amount) || amount.toString().trim() === "") {
      toast.error("Amount should be a number");
      return false;
    }

    if (Number(amount) <= 0) {
      toast.error("Amount can not be 0");
      return false;
    }

    return true;
  };

  const callback = (status) => {
    setLoading(false);
    if (status === RESPONSE.INSUFFICIENT) {
      toast.error("Insufficient balance...");
    } else if (status === RESPONSE.SUCCESS) {
      toast.dark("Success");
      setAmount("0");
      setIsMax(false);
    } else {
      toast.error("Unexpected error...");
    }
  };

  const handleChangeAmount = (value, max) => {
    if (max) {
      setIsMax(true);
    } else {
      setIsMax(false);
    }
    setAmount(value);
  };

  const handleSell = () => {
    if (loading) {
      return;
    }

    if (checkAmount(amount)) {
      setLoading(true);
      dispatch(stakingActions.sellWHL(amount, isMax, callback));
    }
  };

  return (
    <SellBoardWrapper>
      <InputAmount
        amount={amount}
        isStake={false}
        balance={stakedAmount}
        onSetAmount={(value, max) => handleChangeAmount(value, max)}
      />
      <FlexColumnHorizontalCenterWrapper className="button-section">
        <WButton disabled={loading} onClick={handleSell}>
          {loading ? "REMOVING..." : "REMOVE"}
        </WButton>
      </FlexColumnHorizontalCenterWrapper>
    </SellBoardWrapper>
  );
};

const SellBoardWrapper = styled.div`
  margin-top: 30px;

  .button-section {
    margin-top: 50px;
  }
`;

export default SellBoard;
