import React from "react";
import styled from "styled-components";

const PageTitle = ({ title }) => {
  return <PageTitleWrapper>{title}</PageTitleWrapper>;
};

const PageTitleWrapper = styled.h1`
  color: white;
  font-size: 3.55rem;
`;

export default PageTitle;
