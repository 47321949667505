import {
  DEV_WHL_TOKEN_ADDRESS,
  PROD_WHL_TOKEN_ADDRESS,
  WHL_TOKEN_ABI,
  DEV_WHL_STAKING_ADDRESS,
  PROD_WHL_STAKING_ADDRESS,
  WHL_STAKING_ABI,
} from "../../helpers/contract";

const { REACT_APP_BUILD_MODE } = process.env;

export const getWHLStakingInstance = (web3) => {
  let address;
  const abi = WHL_STAKING_ABI;

  if (REACT_APP_BUILD_MODE === "development") {
    address = DEV_WHL_STAKING_ADDRESS;
  } else if (REACT_APP_BUILD_MODE === "production") {
    address = PROD_WHL_STAKING_ADDRESS;
  }

  const instance = new web3.eth.Contract(abi, address);

  return {
    address,
    abi,
    instance,
  };
};

export const getWHLTokenInstance = (web3) => {
  let address;
  const abi = WHL_TOKEN_ABI;

  if (REACT_APP_BUILD_MODE === "development") {
    address = DEV_WHL_TOKEN_ADDRESS;
  } else if (REACT_APP_BUILD_MODE === "production") {
    address = PROD_WHL_TOKEN_ADDRESS;
  }

  const instance = new web3.eth.Contract(abi, address);

  return {
    address,
    abi,
    instance,
  };
};
