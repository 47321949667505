import WHL_TOKEN_ABI from "./abi/WHL_Token_abi.json";
import WHL_STAKING_ABI from "./abi/WHL_Staking_abi.json";

// WHL Token
export const DEV_WHL_TOKEN_ADDRESS = "0x4D7514449C2DAe4c65B5BD38e6D6ecAd8B573F72";
export const PROD_WHL_TOKEN_ADDRESS = "0x2aF72850c504dDD3c1876C66a914cAee7Ff8a46A";
export { WHL_TOKEN_ABI };

// WHL Staking
export const DEV_WHL_STAKING_ADDRESS = "0x8e7ee72c4af7C02631A15C44D28073f88c832B23";
export const PROD_WHL_STAKING_ADDRESS = "0x15E5d409001EAfF5076Af14cd7a4f3268f266445";
export { WHL_STAKING_ABI };

// Gas price multiplier
export const GAS_PRICE_MULTIPLIER = 1.3;
export const CARD_STRENGTH_MULTIPLIER = 100;

export const getGasFee = (gasPrice) => {
  return Math.round(gasPrice * GAS_PRICE_MULTIPLIER);
};
