import React from "react";
import styled from "styled-components";

const RowValue = ({ title, value, className }) => {
  return (
    <RowValueWrapper className={className}>
      <div className="section-title">{title}</div>
      <div className="section-amount">{value}</div>
    </RowValueWrapper>
  );
};

const RowValueWrapper = styled.div`
  color: #ddd;
  display: flex;

  .section-title {
    flex: 1;
  }
`;

export default RowValue;
