import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";

import { WButton } from "../../components/common";
import InputAmount from "../../components/InputAmount";
import { FlexColumnHorizontalCenterWrapper } from "../../components/Wrappers";

import { RESPONSE, REFERRED_BY } from "../../helpers/constant";
import { convertFromWei } from "../../helpers/utils";

import stakingActions from "../../redux/staking/actions";

const StakeBoard = () => {
  const dispatch = useDispatch();

  const whlBalance = useSelector((state) =>
    convertFromWei(state.Staking.whlBalance, 2)
  );
  const whlAllowance = useSelector((state) => state.Staking.whlAllowance);

  const [amount, setAmount] = useState("0");
  const [isMax, setIsMax] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkAmount = (amount) => {
    if (isNaN(amount) || amount.toString().trim() === "") {
      toast.error("Amount should be a number");
      return false;
    }

    if (Number(amount) <= 0) {
      toast.error("Amount can not be 0");
      return false;
    }

    return true;
  };

  const callback = (status) => {
    setLoading(false);
    if (status === RESPONSE.INSUFFICIENT) {
      toast.error("Insufficient balance...");
    } else if (status === RESPONSE.SUCCESS) {
      toast.dark("Success");
      setAmount("0");
      setIsMax(false);
    } else {
      toast.error("Unexpected error...");
    }
  };

  const handleChangeAmount = (value, max) => {
    if (max) {
      setIsMax(true);
    } else {
      setIsMax(false);
    }
    setAmount(value);
  };

  const handleApprove = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    dispatch(stakingActions.approveWHL(callback));
  };

  const handleStake = () => {
    if (loading) {
      return;
    }

    if (checkAmount(amount)) {
      setLoading(true);
      dispatch(stakingActions.stakeWHL(amount, REFERRED_BY, isMax, callback));
    }
  };

  return (
    <StakeBoardWrapper>
      <InputAmount
        amount={amount}
        isStake={true}
        balance={whlBalance}
        onSetAmount={(value, max) => handleChangeAmount(value, max)}
      />
      <FlexColumnHorizontalCenterWrapper className="button-section">
        {whlAllowance > 0 ? (
          <WButton disabled={loading} onClick={handleStake}>
            {loading ? "ADDING..." : "ADD"}
          </WButton>
        ) : (
          <WButton disabled={loading} onClick={handleApprove}>
            {loading ? "APPROVING..." : "APPROVE"}
          </WButton>
        )}
      </FlexColumnHorizontalCenterWrapper>
    </StakeBoardWrapper>
  );
};

const StakeBoardWrapper = styled.div`
  margin-top: 30px;

  .button-section {
    margin-top: 50px;
  }
`;

export default StakeBoard;
