import styled from "styled-components";

export const TopWrapper = styled.div`
  width: 100vw;
  max-width: 1024px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
  margin: 0px auto;
`

export const MainWrapper = styled.div`
  width: 100%;
  background: #36404a;
  padding: 64px;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;

export const FlexColumnHorizontalCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
