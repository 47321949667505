import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import StakeBoard from "../../containers/StakeBoard";
import {
  PageTitle,
  Separator,
  WParagraph,
  LButton,
} from "../../components/common";
import RowValue from "../../components/RowValue";

import { TOKEN_SYMBOL } from "../../helpers/constant";
import { PROD_WHL_TOKEN_ADDRESS } from "../../helpers/contract";
import { convertFromWei } from "../../helpers/utils";

import stakingActions from "../../redux/staking/actions";

const StakePage = () => {
  const dispatch = useDispatch();

  const whlBalance = useSelector((state) => state.Staking.whlBalance);

  useEffect(() => {
    dispatch(stakingActions.getWHLBalance());
    dispatch(stakingActions.getWHLAllowance());
    dispatch(stakingActions.getContractMultiData());
  }, [dispatch]);

  return (
    <StakeWrapper>
      <PageTitle title="Add" />
      <Separator />
      {whlBalance <= 0 && (
        <>
          <WParagraph>
            No {TOKEN_SYMBOL} found in wallet. You can buy some on Uniswap if
            you like!
          </WParagraph>
          <LButton
            size="lg"
            target="_blank"
            to={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${PROD_WHL_TOKEN_ADDRESS}`}
          >
            BUY FROM UNISWAP
          </LButton>
        </>
      )}
      {whlBalance > 0 && (
        <>
          <RowValue
            className="balance-section"
            title="My WHL Points"
            value={`${convertFromWei(whlBalance, 2)} ${TOKEN_SYMBOL}`}
          />
          <StakeBoard />
        </>
      )}
    </StakeWrapper>
  );
};

const StakeWrapper = styled.div`
  .balance-section {
    margin-top: 30px;
    font-size: 1.3rem;
  }
`;

export default StakePage;
