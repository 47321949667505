import React from "react";
import styled from "styled-components";

// lg, md
const WLink = ({ children, ...rest }) => {
  return <WLinkWrapper {...rest}>{children}</WLinkWrapper>;
};

const WLinkWrapper = styled.a`
  color: white;
  font-weight: 600;
`;

export default WLink;
