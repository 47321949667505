const actions = {
  GET_WHL_PRICE: "GET_WHL_PRICE",
  GET_WHL_PRICE_SUCCESS: "GET_WHL_PRICE_SUCCESS",

  GET_WHL_BALANCE: "GET_WHL_BALANCE",
  GET_WHL_BALANCE_SUCCESS: "GET_WHL_BALANCE_SUCCESS",

  GET_WHL_ALLOWANCE: "GET_WHL_ALLOWANCE",
  GET_WHL_ALLOWANCE_SUCCESS: "GET_WHL_ALLOWANCE_SUCCESS",

  // Staked Amount
  GET_STAKED_AMOUNT: "GET_STAKED_AMOUNT",
  GET_STAKED_AMOUNT_SUCCESS: "GET_STAKED_AMOUNT_SUCCESS",

  // Dividends Amount
  GET_DIVIDENDS_AMOUNT: "GET_DIVIDENDS_AMOUNT",
  GET_DIVIDENDS_AMOUNT_SUCCESS: "GET_DIVIDENDS_AMOUNT_SUCCESS",

  // Contract MultiData
  GET_CONTRACT_MULTI_DATA: "GET_CONTRACT_MULTI_DATA",
  GET_CONTRACT_MULTI_DATA_SUCCESS: "GET_CONTRACT_MULTI_DATA_SUCCESS",

  APPROVE_WHL: "APPROVE_WHL",
  STAKE_WHL: "STAKE_WHL",
  SELL_WHL: "SELL_WHL",
  REINVEST_WHL: "REINVEST_WHL",
  WITHDRAW_WHL: "WITHDRAW_WHL",

  getWHLPrice: () => ({
    type: actions.GET_WHL_PRICE
  }),
  getWHLAllowance: () => ({
    type: actions.GET_WHL_ALLOWANCE,
  }),
  getWHLBalance: () => ({
    type: actions.GET_WHL_BALANCE,
  }),
  getStakedAmount: () => ({
    type: actions.GET_STAKED_AMOUNT,
  }),
  getDividendsAmount: () => ({
    type: actions.GET_DIVIDENDS_AMOUNT,
  }),
  getContractMultiData: () => ({
    type: actions.GET_CONTRACT_MULTI_DATA
  }),

  approveWHL: (callback) => ({
    type: actions.APPROVE_WHL,
    payload: { callback },
  }),
  stakeWHL: (amount, referredBy, isMax, callback) => ({
    type: actions.STAKE_WHL,
    payload: { amount, referredBy, isMax, callback }
  }),
  sellWHL: (amount, isMax, callback) => ({
    type: actions.SELL_WHL,
    payload: { amount, isMax, callback }
  }),
  reinvestWHL: (callback) => ({
    type: actions.REINVEST_WHL,
    payload: { callback }
  }),
  withdrawWHL: (callback) => ({
    type: actions.WITHDRAW_WHL,
    payload: { callback }
  })
};

export default actions;
