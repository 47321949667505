import React from "react";
import styled from "styled-components";

import { TOKEN_SYMBOL, REWARD_TOKEN_SYMBOL } from "../../helpers/constant";
import { convertFromWei } from "../../helpers/utils";

const OtherInfo = ({ data = [] }) => {
  return (
    <OtherInfoWrapper>
      {`Contract: ${
        data.length > 1 ? convertFromWei(data[1], 4) : 0
      } ${REWARD_TOKEN_SYMBOL}, ${
        data.length > 0 ? convertFromWei(data[0], 4) : 0
      } ${TOKEN_SYMBOL}`}
    </OtherInfoWrapper>
  );
};

const OtherInfoWrapper = styled.div`
  width: 100%;
  padding: 8px;
  color: #aaa;
  font-size: 0.9rem;
`;

export default OtherInfo;
