import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { UseWalletProvider } from "use-wallet";
import { StylesProvider } from "@material-ui/styles";

import Routes from "./routes";

import { getNetworkChainId } from "./helpers/constant";
// import { Announcement } from "./components/layout";
import { TopWrapper } from "./components/Wrappers";

import "./vendor/index.scss";

const App = () => {
  return (
    <StylesProvider injectFirst>
      <UseWalletProvider chainId={getNetworkChainId()}>
        {/*<Announcement />*/}
        <TopWrapper>
          <Router>
            <Routes />
          </Router>
        </TopWrapper>
        <ToastContainer />
      </UseWalletProvider>
    </StylesProvider>
  );
};

export default App;
