import { all, takeLatest, call, put, fork } from "redux-saga/effects";

import BigNumber from "bignumber.js";

import actions from "./actions";

import { RESPONSE } from "../../helpers/constant";

import { getWeb3 } from "../../services/web3";
import {
  getBalanceAsync,
  getAllowanceAsync,
  getDividendsAsync,
  getContractMultiDataAsync,
  approveAsync,
  stakeAsync,
  sellAsync,
  withdrawAsync,
  reinvestAsync
} from "../../services/web3/functions";

import { PROD_WHL_TOKEN_ADDRESS } from "../../helpers/contract";
import { getTokenInfo } from "../../services/graphql";
import { lookUpPrices } from "../../services/web3";

import {
  getWHLStakingInstance,
  getWHLTokenInstance
} from "../../services/web3/instance";

// WHL price
export function* getWHLPrice() {
  yield takeLatest(actions.GET_WHL_PRICE, function* () {

    const ethPrice = (yield call(lookUpPrices, ["ethereum"])).ethereum.usd;

    const whlTokenInfo = yield call(getTokenInfo, PROD_WHL_TOKEN_ADDRESS);

    const whlPrice = whlTokenInfo.derivedETH * ethPrice;

    yield put({
      type: actions.GET_WHL_PRICE_SUCCESS,
      whlPrice: whlPrice.toFixed(2),
    });
  });
}

// WHL balance
export function* getWHLBalance() {
  yield takeLatest(actions.GET_WHL_BALANCE, function* () {
    const web3 = yield call(getWeb3);
    const whl = getWHLTokenInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    const whlBalance = yield call(getBalanceAsync, whl.instance, accounts[0]);

    yield put({
      type: actions.GET_WHL_BALANCE_SUCCESS,
      whlBalance: whlBalance,
    });
  });
}

// WHL allowance
export function* getWHLAllowance() {
  yield takeLatest(actions.GET_WHL_ALLOWANCE, function* () {
    const web3 = yield call(getWeb3);
    const whl = getWHLTokenInstance(web3);
    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    const whlAllowance = yield call(getAllowanceAsync, whl.instance, accounts[0], whlStaking.address);

    yield put({
      type: actions.GET_WHL_ALLOWANCE_SUCCESS,
      whlAllowance,
    });
  });
}

// Staked Amount
export function* getStakedAmount() {
  yield takeLatest(actions.GET_STAKED_AMOUNT, function* () {
    const web3 = yield call(getWeb3);
    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    const stakedAmount = yield call(getBalanceAsync, whlStaking.instance, accounts[0]);

    yield put({
      type: actions.GET_STAKED_AMOUNT_SUCCESS,
      stakedAmount,
    });
  });
}

// Staked Amount
export function* getDividendsAmount() {
  yield takeLatest(actions.GET_DIVIDENDS_AMOUNT, function* () {
    const web3 = yield call(getWeb3);
    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    const dividendsAmount = yield call(getDividendsAsync, whlStaking.instance, accounts[0]);

    yield put({
      type: actions.GET_DIVIDENDS_AMOUNT_SUCCESS,
      dividendsAmount,
    });
  });
}

// Get Contract Multi Data
export function* getContractMultiData() {
  yield takeLatest(actions.GET_CONTRACT_MULTI_DATA, function* () {
    const web3 = yield call(getWeb3);
    const whlStaking = getWHLStakingInstance(web3);

    const multiDataResult = yield call(getContractMultiDataAsync, whlStaking.instance);

    const data = [];
    Object.keys(multiDataResult).forEach((key) => {
      data.push(multiDataResult[key]);
    });

    yield put({
      type: actions.GET_CONTRACT_MULTI_DATA_SUCCESS,
      data,
    });
  });
}

export function* approveWHL() {
  yield takeLatest(actions.APPROVE_WHL, function* ({ payload }) {
    const { callback } = payload;

    const web3 = yield call(getWeb3);
    const whl = getWHLTokenInstance(web3);
    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    // Approve
    const approveResult = yield call(
      approveAsync,
      whl.instance,
      web3,
      0,
      accounts[0],
      whlStaking.address
    );

    // console.log("approve result", approveResult);
    if (approveResult.status) {
      yield put({ type: actions.GET_WHL_ALLOWANCE });
      callback(RESPONSE.SUCCESS);
    } else {
      callback(RESPONSE.ERROR);
    }
  });
}

export function* stakeWHL() {
  yield takeLatest(actions.STAKE_WHL, function* ({ payload }) {
    const { amount, referredBy, isMax, callback } = payload;

    const web3 = yield call(getWeb3);

    const whl = getWHLTokenInstance(web3);
    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    // Check balance
    const whlBalance = yield call(
      getBalanceAsync,
      whl.instance,
      accounts[0]
    );

    const stakeAmount = isMax
      ? new BigNumber(whlBalance)
      : new BigNumber(amount).times(new BigNumber(10).pow(18));

    if (new BigNumber(whlBalance).comparedTo(stakeAmount) === -1) {
      callback(RESPONSE.INSUFFICIENT);
      return;
    }

    const stakeResult = yield call(
      stakeAsync,
      whlStaking.instance,
      web3,
      stakeAmount,
      referredBy,
      accounts[0]
    );

    if (stakeResult.status) {
      yield put({ type: actions.GET_WHL_BALANCE });
      yield put({ type: actions.GET_CONTRACT_MULTI_DATA });
      callback(RESPONSE.SUCCESS);
    } else {
      callback(RESPONSE.ERROR);
    }
  });
}

export function* sellWHL() {
  yield takeLatest(actions.SELL_WHL, function* ({ payload }) {
    const { amount, isMax, callback } = payload;

    const web3 = yield call(getWeb3);

    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    // Check balance
    const stakedAmount = yield call(
      getBalanceAsync,
      whlStaking.instance,
      accounts[0]
    );

    const sellAmount = isMax
      ? new BigNumber(stakedAmount)
      : new BigNumber(amount).times(new BigNumber(10).pow(18));

    if (new BigNumber(stakedAmount).comparedTo(sellAmount) === -1) {
      callback(RESPONSE.INSUFFICIENT);
      return;
    }

    const sellResult = yield call(
      sellAsync,
      whlStaking.instance,
      web3,
      sellAmount,
      accounts[0]
    );

    if (sellResult.status) {
      yield put({ type: actions.GET_STAKED_AMOUNT });
      yield put({ type: actions.GET_CONTRACT_MULTI_DATA });
      callback(RESPONSE.SUCCESS);
    } else {
      callback(RESPONSE.ERROR);
    }
  });
}

export function* reinvestWHL() {
  yield takeLatest(actions.REINVEST_WHL, function* ({ payload }) {
    const { callback } = payload;

    const web3 = yield call(getWeb3);

    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    const reinvestResult = yield call(
      reinvestAsync,
      whlStaking.instance,
      web3,
      accounts[0]
    );

    if (reinvestResult.status) {
      yield put({ type: actions.GET_STAKED_AMOUNT });
      yield put({ type: actions.GET_DIVIDENDS_AMOUNT });
      yield put({ type: actions.GET_CONTRACT_MULTI_DATA });
      callback(RESPONSE.SUCCESS);
    } else {
      callback(RESPONSE.ERROR);
    }
  });
}

export function* withdrawWHL() {
  yield takeLatest(actions.WITHDRAW_WHL, function* ({ payload }) {
    const { callback } = payload;

    const web3 = yield call(getWeb3);

    const whlStaking = getWHLStakingInstance(web3);

    // Get Wallet Account
    const accounts = yield call(web3.eth.getAccounts);

    const withdrawResult = yield call(
      withdrawAsync,
      whlStaking.instance,
      web3,
      accounts[0]
    );

    if (withdrawResult.status) {
      yield put({ type: actions.GET_STAKED_AMOUNT });
      yield put({ type: actions.GET_DIVIDENDS_AMOUNT });
      yield put({ type: actions.GET_CONTRACT_MULTI_DATA });
      callback(RESPONSE.SUCCESS);
    } else {
      callback(RESPONSE.ERROR);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getWHLPrice),
    fork(getWHLBalance),
    fork(getStakedAmount),
    fork(getWHLAllowance),
    fork(getDividendsAmount),
    fork(getContractMultiData),
    fork(approveWHL),
    fork(stakeWHL),
    fork(sellWHL),
    fork(withdrawWHL),
    fork(reinvestWHL)
  ]);
}
