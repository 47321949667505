import React from "react";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";

const Header = () => {
  return (
    <HeaderWrapper>
      <Link to="/" className="header-logo">
        <img src="img/etherscan-whl.png" height="90" />
        <div className="logo-description">
          <h1>WhaleRoom Research</h1>
          <span>Loyalty Game</span>
        </div>
      </Link>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px 0;

  .header-logo {
    display: flex;
    text-decoration: none;
    width: 100%;
    max-width: 100%;
  }

  .logo-description {
    color: white;
    padding-left: 12px;

    h1 {
      font-size: 1rem;
      margin-top: 25px;
    }

    span {
      font-size: 1.2rem;

      sup {
        font-size: 0.8rem;
      }
    }
  }
`;

export default withRouter(Header);
