import React from "react";
import styled from "styled-components";

// lg, md
const LButton = ({ children, to = "/", size = "md", ...rest }) => {
  return (
    <LButtonWrapper className={size} href={to} {...rest}>
      {children}
    </LButtonWrapper>
  );
};

const LButtonWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #262e35;
  color: white;
  height: 60px;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 0.90625rem;
  cursor: pointer;
  text-decoration: none;

  &.lg {
    width: 300px;
  }

  &.md {
    width: 230px;
  }
`;

export default LButton;
