import React from "react";
import styled from "styled-components";
import cn from "classnames";

// lg, md
const WButton = ({
  children,
  onClick = () => {},
  size = "md",
  disabled = false,
  ...rest
}) => {
  return (
    <WButtonWrapper
      className={cn(size, { disabled })}
      onClick={(e) => onClick()}
      {...rest}
    >
      {children}
    </WButtonWrapper>
  );
};

const WButtonWrapper = styled.button`
  background: #262e35;
  color: white;
  height: 60px;
  border: none;
  outline: none;
  font-weight: 700;
  font-size: 0.90625rem;
  cursor: pointer;

  &.lg {
    width: 300px;
  }

  &.md {
    width: 230px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default WButton;
